.flip-box {
    background-color: transparent;
    width: 100;
    height: 15rem;
    border-radius: 0.5rem;
    text-align: center;
    /* border: 1px solid #f1f1f1; */
    perspective: 1000px; /* Remove this if you don't want the 3D effect */
  }
  
  /* This container is needed to position the front and back side */
  .flip-box-inner {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    transition: transform 0.8s;
    transform-style: preserve-3d;
  }
  
  /* Do an horizontal flip when you move the mouse over the flip box container */
  .flip-box:hover .flip-box-inner {
    transform: rotateY(180deg);
  }
  
  /* Position the front and back side */
  .flip-box-front, .flip-box-back {
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden; /* Safari */
    backface-visibility: hidden;
  }
  
  /* Style the front side (fallback if image is missing) */
  .flip-box-front {
    background-color: #bbb;
    color: black;
    background-color: transparent;
    perspective: 1000px;
  }
  
  /* Style the back side */
  .flip-box-back {
    background-color: #fff !important;
    color: black;
    font-weight: 400;
    transform: rotateY(180deg);
    box-shadow: 2px 2px 2px 2px rgba(0, 0, 0, 0.2) !important;
  }

.expert-description {
  text-align: center;
  top: 50%;
  padding: 5%;
}
.red--text {
  color: red;
}