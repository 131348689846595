.social-media {
    position: fixed;
    bottom: 40%;
    right: 0;
    display: flex;
    flex-direction: column;
    z-index: 1;
}
.text-style {
    color: #EDEADE !important;
    /* text-align: center; */
}